import { MDXProvider } from '@mdx-js/react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import MainLayout from '../components/layout/MainLayout';
import Expandable from '../components/mdx/expandable';
import Spacing from '../components/mdx/spacing';
import Section from '../components/Section';
import SearchEngineOptimization from '../components/utility/seo';

const shortcodes = { Link, Expandable, Spacing };

const Mdx = ({ data: { mdx } }) => {
  return (
    <MainLayout>
      <SearchEngineOptimization title={mdx.frontmatter.title} />
      <Section className="!py-6">
        <div className="p-6 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border">
          <MDXProvider components={shortcodes}>
            <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </Section>
    </MainLayout>
  );
};

export const pageQuery = graphql`
  query MdxQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`;

export default Mdx;
